<template>
  <div :class="{'container': isPc, 'container-mb': !isPc}">
    <div class="item">
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M16.454 38.0599C12.6502 37.7945 8.8463 37.0868 5.30784 35.8484C2.38861 34.7868 0.884766 33.5484 0.884766 32.2215C0.884766 31.3369 1.50401 30.5408 2.83093 29.7446C4.5117 28.86 6.19246 28.1523 8.05016 27.71L7.25401 29.6561C7.16555 29.833 7.25399 30.0985 7.43091 30.1869C7.51938 30.1869 7.51939 30.1869 7.60785 30.1869H17.6925C17.9578 30.1869 18.1348 30.3639 18.1348 30.6292V30.7177L16.454 38.0599Z" fill="#1C73FF"/>
        <path d="M11.9433 27.9755C11.9433 27.887 11.9433 27.887 11.9433 27.9755H20.5241C20.7895 27.9755 21.0548 28.2408 21.0548 28.5062V28.5947L17.9587 42.1293C17.9587 42.2178 17.8703 42.3061 17.8703 42.4831C17.8703 43.1908 18.4895 43.6331 19.1087 43.6331C19.4626 43.6331 19.7279 43.4562 19.9933 43.1908L39.1895 20.3677C39.1895 20.3677 39.1895 20.3677 39.2779 20.2792C39.7202 19.7485 39.6318 19.0408 39.1895 18.5985C39.0125 18.4215 38.6587 18.3331 38.3933 18.3331C38.3933 18.3331 38.3933 18.3331 38.3048 18.3331L31.4049 18.2447C31.1395 18.2447 30.8741 17.9792 30.8741 17.7138C30.8741 17.6254 30.8741 17.537 30.9625 17.537L35.9164 6.83313C36.0049 6.65621 36.0049 6.47931 36.0049 6.30239C36.0049 5.59469 35.3856 5.15234 34.7664 5.15234H20.7895C20.7895 5.15234 20.7895 5.15234 20.701 5.15234C20.2587 5.15234 19.8164 5.41777 19.6395 5.86008C19.6395 5.86008 19.6395 5.86006 19.6395 5.94852L10.8818 26.2947C10.6164 26.9139 10.8818 27.6215 11.501 27.8869C11.5895 27.8869 11.7664 27.9755 11.9433 27.9755Z" fill="#1C73FF"/>
        <path opacity="0.4" d="M27.4238 38.2367L36.4469 27.4443C38.6585 27.8866 40.7815 28.5944 42.7277 29.5674C44.32 30.452 45.1161 31.3366 45.1161 32.3097C45.1161 33.7251 43.3469 35.1405 40.0738 36.2021C35.9161 37.352 31.67 38.0598 27.4238 38.2367Z" fill="#1C73FF"/>
      </svg>
      <span>快如閃電</span>
    </div>
    <div class="item">
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.65415 4.96094H13.7118C14.2426 4.96094 14.5965 5.31478 14.5965 5.84555V39.6378C14.5965 40.1686 14.2426 40.5224 13.7118 40.5224H2.65415C2.12338 40.5224 1.76953 40.1686 1.76953 39.6378V5.84555C1.76953 5.31478 2.12338 4.96094 2.65415 4.96094Z" fill="#A0C4FE"/>
        <path d="M32.2889 4.96191H43.3466C43.8774 4.96191 44.2312 5.31576 44.2312 5.84653V39.6388C44.2312 40.1696 43.8774 40.5234 43.3466 40.5234H32.2889C31.7581 40.5234 31.4043 40.1696 31.4043 39.6388V5.84653C31.4043 5.31576 31.7581 4.96191 32.2889 4.96191Z" fill="#A0C4FE"/>
        <path d="M30.0545 21.3769C29.7007 21.3769 29.3468 21.2884 29.1699 21.023C28.9045 20.7576 28.7276 20.4038 28.7276 20.05V18.723H18.9968C18.2892 18.723 17.6699 18.1038 17.6699 17.3076V13.3269C17.6699 12.5307 18.2892 11.9115 19.0853 11.9115H28.8161V10.7615C28.7276 9.96534 29.3468 9.43457 30.0545 9.43457C30.3199 9.43457 30.5853 9.52303 30.7622 9.61149L37.9276 14.3C38.2815 14.5653 38.5468 14.9192 38.5468 15.45C38.5468 15.9807 38.2815 16.3346 37.9276 16.6L30.7622 21.2884C30.5853 21.2884 30.3199 21.3769 30.0545 21.3769Z" fill="#1C73FF"/>
        <path d="M30.0544 20.4921C30.1428 20.4921 30.2313 20.4921 30.3198 20.4037L37.4852 15.7152C37.7505 15.5383 37.7505 15.1844 37.4852 15.0075L30.3198 10.319C30.2313 10.319 30.1428 10.319 30.0544 10.319C29.789 10.319 29.6121 10.496 29.6121 10.7613V12.8844H18.9967C18.8198 12.8844 18.5544 13.0613 18.5544 13.3267V17.3075C18.5544 17.5729 18.8198 17.8383 19.0852 17.8383H29.7005V20.0498C29.6121 20.3152 29.8775 20.4921 30.0544 20.4921ZM30.0544 22.2613C29.5236 22.2613 28.9044 22.0844 28.5505 21.6421C28.1082 21.1998 27.8428 20.669 27.8428 20.0498V19.519H18.9967C17.7582 19.6075 16.7852 18.546 16.7852 17.3075V13.3267C16.7852 12.0883 17.7582 11.1152 19.0852 11.1152H27.9313V10.7613C27.8428 9.52288 28.8159 8.5498 30.0544 8.5498C30.4967 8.5498 30.939 8.63827 31.2928 8.90365L38.4582 13.5921C39.0775 14.0344 39.4313 14.6537 39.4313 15.4498C39.4313 16.246 39.0775 16.8652 38.4582 17.3075L31.2928 21.996C30.939 22.1729 30.4967 22.2613 30.0544 22.2613Z" fill="#F8FAFC"/>
        <path d="M15.2502 24.2218C15.604 24.2218 15.9578 24.3102 16.1348 24.5756C16.4002 24.841 16.5771 25.1948 16.5771 25.5487V26.7871H26.3078C27.0155 26.7871 27.6348 27.4064 27.6348 28.2025V32.0948C27.6348 32.891 27.0155 33.5102 26.2194 33.5102H16.4886V34.8371C16.5771 35.6333 15.9578 36.1641 15.2502 36.1641C14.9848 36.1641 14.7194 36.0756 14.5425 35.9871L7.37707 31.2987C7.02323 31.0333 6.75784 30.6794 6.75784 30.1487C6.75784 29.6179 7.02323 29.2641 7.37707 28.9987L14.5425 24.3102C14.7194 24.3102 14.9848 24.2218 15.2502 24.2218Z" fill="#1C73FF"/>
        <path d="M15.2503 25.1065C15.1618 25.1065 15.0734 25.1065 14.9849 25.195L7.81953 29.8834C7.55415 30.0604 7.55415 30.4142 7.81953 30.5911L14.9849 35.2796C15.0734 35.2796 15.1618 35.2796 15.2503 35.2796C15.5157 35.2796 15.6926 35.1027 15.6926 34.8373V32.6258H26.308C26.4849 32.6258 26.7503 32.3604 26.7503 32.095V28.2027C26.7503 27.9373 26.4849 27.6719 26.2195 27.6719H15.6041V25.5488C15.6926 25.2834 15.4272 25.1065 15.2503 25.1065ZM15.2503 23.3373C15.7811 23.3373 16.4003 23.5142 16.7541 23.9565C17.1965 24.3988 17.4618 24.9296 17.4618 25.5488V25.9027H26.308C27.5465 25.9027 28.5195 26.8758 28.5195 28.2027V32.095C28.5195 33.3334 27.5465 34.395 26.2195 34.395H17.3734V34.8373C17.4618 36.0758 16.4888 37.0488 15.2503 37.0488C14.808 37.0488 14.3657 36.9604 14.0118 36.695L6.84645 32.0065C6.22722 31.5642 5.87338 30.945 5.87338 30.1488C5.87338 29.3527 6.22722 28.7334 6.84645 28.2911L14.0118 23.6027C14.3657 23.4258 14.808 23.3373 15.2503 23.3373Z" fill="#F8FAFC"/>
      </svg>
      <span>穩定加速</span>
    </div>
    <div class="item">
      <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.4 7.61461C12.7385 7.52615 7.87307 11.8607 7.43076 17.5223C3.18461 18.5838 0.176923 22.2992 0 26.6338C0 31.6761 4.33462 35.8338 9.81924 36.0992H28.2192C34.4115 36.0107 39.2769 30.9685 39.2769 24.7762C39.1885 18.6723 34.4115 13.8069 28.3077 13.5415C26.3615 9.91457 22.5577 7.61461 18.4 7.61461Z" fill="#1C73FF"/>
        <path d="M44.6722 24.2466L36.3568 20.5312C36.1799 20.4427 36.003 20.4427 35.826 20.5312L27.5107 24.2466C27.2453 24.3351 27.0684 24.6004 27.0684 24.9543V33.8889C27.1568 38.2235 34.4991 41.5851 35.9145 42.1158C36.0914 42.2043 36.2684 42.2043 36.4453 42.1158C37.8607 41.4966 45.203 38.2235 45.203 33.8004V24.8658C45.1145 24.6004 44.9376 24.3351 44.6722 24.2466Z" fill="#ADC1F8" stroke="#F8FAFC" stroke-width="2"/>
        <path d="M36.6224 33.0921L35.207 34.5075L31.5801 30.8806L32.9954 29.4651L35.2954 31.7652L39.4531 27.6074L40.8685 29.0228L36.6224 33.0921Z" fill="white"/>
      </svg>
      <span>增強安全</span>
    </div>
    <div class="item">
      <svg width="49" height="46" viewBox="0 0 49 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.654 6.73047H37.2963C38.2694 6.73047 39.0655 7.52662 39.0655 8.4997V35.1266C39.0655 36.0997 38.2694 36.8958 37.2963 36.8958H4.654C3.68092 36.8958 2.88477 36.0997 2.88477 35.1266V8.4997C2.88477 7.52662 3.68092 6.73047 4.654 6.73047Z" fill="#1C73FF"/>
        <path d="M6.42368 12.7471H35.9698C36.5006 12.7471 36.8544 13.1009 36.8544 13.6317V33.0932C36.8544 33.624 36.5006 33.9778 35.9698 33.9778H6.42368C5.89291 33.9778 5.53906 33.624 5.53906 33.0932V13.6317C5.53906 13.1894 5.89291 12.7471 6.42368 12.7471Z" fill="white"/>
        <path d="M6.86566 10.7117C7.40308 10.7117 7.83874 10.2761 7.83874 9.73868C7.83874 9.20126 7.40308 8.76562 6.86566 8.76562C6.32824 8.76562 5.89258 9.20126 5.89258 9.73868C5.89258 10.2761 6.32824 10.7117 6.86566 10.7117Z" fill="white"/>
        <path d="M10.934 10.7117C11.4714 10.7117 11.9071 10.2761 11.9071 9.73868C11.9071 9.20126 11.4714 8.76562 10.934 8.76562C10.3966 8.76562 9.96094 9.20126 9.96094 9.73868C9.96094 10.2761 10.3966 10.7117 10.934 10.7117Z" fill="white"/>
        <path d="M14.9145 10.7117C15.4519 10.7117 15.8876 10.2761 15.8876 9.73868C15.8876 9.20126 15.4519 8.76562 14.9145 8.76562C14.3771 8.76562 13.9414 9.20126 13.9414 9.73868C13.9414 10.2761 14.3771 10.7117 14.9145 10.7117Z" fill="white"/>
        <path d="M31.0159 17.7881H11.9082V19.8227H31.0159V17.7881Z" fill="#1C73FF"/>
        <path d="M21.0182 22.8301H10.9336V24.8647H21.0182V22.8301Z" fill="#1C73FF"/>
        <path d="M24.026 27.8721H13.9414V29.9066H24.026V27.8721Z" fill="#1C73FF"/>
        <path d="M37.5617 41.9379C42.8381 41.9379 47.1155 37.6604 47.1155 32.384C47.1155 27.1075 42.8381 22.8301 37.5617 22.8301C32.2852 22.8301 28.0078 27.1075 28.0078 32.384C28.0078 37.6604 32.2852 41.9379 37.5617 41.9379Z" fill="#ADC1F8" stroke="white" stroke-width="2"/>
        <path d="M41.0451 26.8496C40.7797 26.8496 39.9836 27.9996 39.9836 27.9996C38.3913 27.5573 36.7105 27.5573 35.2067 27.9996C34.4105 28.265 34.4105 26.8496 34.1451 26.8496C33.8797 26.8496 32.7297 30.9188 31.9336 40.3842C35.2951 42.9496 39.9836 42.9496 43.2567 40.3842C42.4605 30.9188 41.3105 26.8496 41.0451 26.8496Z" fill="white"/>
        <path d="M43.6104 29.4142C43.5219 29.4142 43.4334 29.4142 43.345 29.4142C42.8142 29.4142 42.2834 29.3257 41.8411 29.3257C40.7796 29.2373 39.8065 29.4142 38.8334 29.6796C38.0373 30.0334 37.1527 30.0334 36.3565 29.6796C35.3834 29.4142 34.4104 29.2373 33.3488 29.3257C32.8181 29.3257 32.3758 29.3257 31.845 29.4142C31.4911 29.4142 31.0488 29.5027 31.0488 29.945C31.0488 30.1219 31.1373 30.2988 31.2258 30.3873C31.3142 30.4757 31.4911 30.3873 31.5796 30.4757C31.6681 30.5642 31.6681 30.7411 31.6681 30.8296C31.5796 31.8027 31.9334 32.6873 32.6411 33.3065C33.3488 33.6604 34.2334 33.8373 35.0296 33.7488C35.7373 33.7488 36.3565 33.3065 36.7104 32.6873C36.7988 32.3334 36.9758 31.9796 36.9758 31.6257C36.9758 31.6257 37.2411 30.7411 37.595 30.7411C37.9488 30.7411 38.2142 31.6257 38.2142 31.6257C38.3027 31.9796 38.3911 32.3334 38.4796 32.6873C38.745 33.3065 39.4527 33.7488 40.1604 33.7488C40.9565 33.8373 41.8411 33.6604 42.5488 33.3065C43.2565 32.6873 43.6988 31.8027 43.6104 30.8296C43.6104 30.7411 43.6104 30.5642 43.6988 30.4757C43.7873 30.3873 43.9642 30.3873 44.0527 30.3873C44.1411 30.2988 44.2296 30.1219 44.2296 30.0334C44.1411 29.6796 43.9642 29.5027 43.6104 29.4142Z" fill="#6089FF"/>
      </svg>
      <span>保持匿名</span>
    </div>
    <div class="item">
      <svg width="48" height="46" viewBox="0 0 48 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.654 6.96094H39.9501C40.9232 6.96094 41.7194 7.75709 41.7194 8.73017V35.4455C41.7194 36.4186 40.9232 37.2147 39.9501 37.2147H4.654C3.68092 37.2147 2.88477 36.4186 2.88477 35.4455V8.73017C2.88477 7.75709 3.68092 6.96094 4.654 6.96094Z" fill="#1C73FF"/>
        <path d="M6.42368 9.96777H38.2698C38.8006 9.96777 39.1544 10.3216 39.1544 10.8524V31.9062C39.1544 32.4369 38.8006 32.7908 38.2698 32.7908H6.42368C5.89291 32.7908 5.53906 32.4369 5.53906 31.9062V10.8524C5.53906 10.4101 5.89291 9.96777 6.42368 9.96777Z" fill="#F8FAFC"/>
        <path d="M28.3615 20.2303C28.8038 20.4957 28.8923 21.0265 28.7153 21.4688C28.6269 21.5573 28.5384 21.7342 28.3615 21.8227L19.073 27.3073C18.6307 27.5727 18.0999 27.3957 17.8346 26.9534C17.7461 26.7765 17.7461 26.688 17.7461 26.5111V15.5418C17.7461 15.0111 18.0999 14.6572 18.6307 14.6572C18.8076 14.6572 18.9846 14.6573 19.073 14.7458L28.3615 20.2303Z" fill="#1C73FF"/>
        <path d="M47.1143 24.2119H28.8027V42.5234H47.1143V24.2119Z" fill="#F8FAFC"/>
        <path d="M42.2494 30.7576C42.2494 28.6345 40.4802 26.8652 38.3571 26.8652C36.2341 26.8652 34.4648 28.6345 34.4648 30.7576V34.296" stroke="#ADC1F8" stroke-width="2" stroke-linecap="round"/>
        <path d="M31.8123 33.5H44.2854C44.8162 33.5 45.17 33.8538 45.17 34.3846V43.2308C45.17 43.7615 44.8162 44.1154 44.2854 44.1154H31.8123C31.2816 44.1154 30.9277 43.7615 30.9277 43.2308V34.3846C30.9277 33.8538 31.37 33.5 31.8123 33.5Z" fill="#ADC1F8"/>
        <path d="M37.3854 38.8058V40.6635C37.3854 41.0174 37.6508 41.2828 38.0047 41.2828C38.3585 41.2828 38.6239 41.0174 38.6239 40.6635V38.8058C38.9777 38.5405 39.1546 38.1867 39.1546 37.7443C39.1546 37.0367 38.6239 36.5059 37.9162 36.5059C37.2085 36.5059 36.6777 37.0367 36.6777 37.7443C36.8547 38.1867 37.0316 38.6289 37.3854 38.8058Z" fill="white"/>
      </svg>
      <span>触及內容</span>
    </div>
    <div class="item">
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.76953 12.9204V4.07422H11.6157V6.72807H5.42338V12.9204H2.76953Z" fill="#ADC1F8"/>
        <path d="M39.9243 12.9204V4.07422H31.0781V6.72807H37.2704V12.9204H39.9243Z" fill="#ADC1F8"/>
        <path d="M2.76953 32.3818V41.228H11.6157V38.5741H5.42338V32.3818H2.76953Z" fill="#ADC1F8"/>
        <path d="M39.9243 32.3818V41.228H31.0781V38.5741H37.2704V32.3818H39.9243Z" fill="#ADC1F8"/>
        <path d="M21.3454 36.277C15.8608 36.1885 11.5261 31.7654 11.6146 26.2808C11.6146 24.6 12.0569 22.9193 12.8531 21.4154C12.8531 21.3269 12.9415 21.327 12.9415 21.2385L19.93 9.29622C20.3723 8.50007 21.4338 8.23462 22.23 8.67693C22.4954 8.85385 22.6723 9.03084 22.8492 9.29622L24.3531 11.8616L29.7492 21.1501C29.8377 21.2386 29.9261 21.4154 29.9261 21.5038C31.3415 24.1577 31.5184 27.3424 30.3684 30.1731C29.9261 31.3231 29.2185 32.4731 28.2454 33.3577C27.3608 34.2423 26.2993 35.0385 25.1493 35.4808C23.9108 36.0116 22.6723 36.277 21.3454 36.277Z" fill="#1C73FF"/>
        <path d="M17.3652 26.8972L17.9844 26.5433C19.046 25.8357 20.3729 25.8357 21.5229 26.4549H21.6114C21.6999 26.4549 21.6998 26.5433 21.7883 26.5433C22.8498 27.251 24.0883 27.3395 25.2383 26.7202L25.946 26.3664" stroke="#F8FAFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span>不限流量</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Advantage',
  data() {
    return {
      isPc: this.$isPc(),
    }
  },
}
</script>
<style lang="scss">
.container {
  height: 300px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      margin-top: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 27px;
      color: #5F6376;
    }
  }
}

.container-mb {
  height: 7.76rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;

    svg {
      height: .85rem;
      width: .85rem;
    }

    span {
      margin-top: .23rem;
      font-size: .433rem;
      font-weight: 400;
      color: #5F6376;
    }
  }
}

</style>
