import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)
app.config.globalProperties.$assetsUrl = process.env.VUE_APP_ASSETS_URL;
app.config.globalProperties.$isPc = () => {
    const width = document.documentElement.clientWidth || document.body.clientWidth;
    return width > 500;
}

app.mount('#app');
