<template>
  <header :class="{ 'header-container': isPc, 'header-container-mobile': !isPc }" >
    <div class="head">
      <div class="header-left">
        <a class="home-text" :style="{
            backgroundImage: 'url(' + $assetsUrl + 'navigation-logo.svg)',
          }"></a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      isPc: this.$isPc(),
    };
  }
}
</script>
<style scoped lang="scss">
.header-container {
  background: #fff;
  height: 88px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e5e9ed;

  .head {
    max-width: 1170px;
    width: 100%;
    min-width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .header-left {
      display: flex;
      align-items: center;
    }

    .home-text {
      height: 32px;
      width: 192px;
      background-size: 100% 100%;
    }
  }
}

.header-container-mobile {
  background: #fff;
  width: 100%;

  .header-left {
    height: 1.6rem; /* 120/75 */
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0.067rem 0.093rem rgba(205, 205, 205, 0.2);
    padding: 0 0.533rem;

    a {
      color: #242c45;
    }

    a.home-text {
      height: 0.8rem;
      width: 4rem;
      background: center no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
