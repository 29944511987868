<template>
  <div :class="{'preview-session-container': isPc, 'preview-session-container-mb': !isPc}" class="home-item">
    <div class="content preview-session-flag">
      <div class="content-left ">
        <p class="title">JetStream 加速最穩妥</p>
        <p class="description">港科大、斯坦福博士研發加速絕技，穩定触及美日英台陸各地內容。</p>
        <a-button class="use-btn" type="primary" @click="gotoHomePage()">4元试用3天</a-button>
        <div class="bottom-description">
          <div class="bottom-description-item">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="#EBEFFD"/>
              <path d="M12.4107 5.625L7.84582 10.1942L5.58934 7.93558L4.5 9.02596L6.75648 11.2846L7.84582 12.375L13.5 6.71538L12.4107 5.625Z" fill="#3963EF"/>
            </svg>
            <span>多协议专线</span>
          </div>
          <div class="bottom-description-item">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="#EBEFFD"/>
              <path d="M12.4107 5.625L7.84582 10.1942L5.58934 7.93558L4.5 9.02596L6.75648 11.2846L7.84582 12.375L13.5 6.71538L12.4107 5.625Z" fill="#3963EF"/>
            </svg>
            <span>全平台通用</span>
          </div>
          <div class="bottom-description-item">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="#EBEFFD"/>
              <path d="M12.4107 5.625L7.84582 10.1942L5.58934 7.93558L4.5 9.02596L6.75648 11.2846L7.84582 12.375L13.5 6.71538L12.4107 5.625Z" fill="#3963EF"/>
            </svg>
            <span>真人客服</span>
          </div>
        </div>
      </div>
      <div class="ani-container">
        <i class="show-time-circle"
           :style="{backgroundImage: 'url(' + $assetsUrl + 'home-anim-circle.svg)'}">
        </i>
        <i class="show-time-phone"
           :style="{backgroundImage: 'url(' + this.$assetsUrl + 'home-anim-phone.svg)'}"></i>
        <div class="move-block" v-for="item of iconList" :key="item.img">
          <img class="move-icon" :src="item.img" alt=""/>
        </div>
        <div class="lottie-content">
          <i id="connect-loading" :style="{backgroundImage: 'url(' + this.$assetsUrl + 'connect-loading.svg)'}"
             :class="{'connect-loading-hide': connectLoading}" class="connect-loading"></i>
          <i class="connect-stand-by"
             :style="{backgroundImage: 'url(' + this.$assetsUrl + 'connect-stand-by.svg)'}"
             id="connect-stand-by" :class="{'connect-stand-by-hide': hideConnectBG}"></i>
          <i class="connect-stand-by-by" id="connect-stand-by-by" :class="{'connect-stand-by-hide': hideConnectBG}"></i>
          <div id="lottie-centre" class="lottie-centre"></div>
          <div id="lottie-centre-arrow" class="lottie-centre-arrow"></div>
        </div>
      </div>
    </div>
  </div>
  <Prompt v-if="showPrompt" @click="hidePrompt()"/>
</template>

<script>
import lottie from 'lottie-web';
import connectedJson from '@/assets/json/connected.json';
import connectedArrowJson from '@/assets/json/connected-arrow.json';
import { TweenMax, Power0, Power1, Power2 } from 'gsap';
import Prompt from "./Prompt";

export default {
  name: 'HomePreviewSession',
  components: {Prompt},
  data() {
    return {
      iconList: [
        {
          img: (`${this.$assetsUrl}/icon-whatsapp.svg`),
        },
        {
          img: (`${this.$assetsUrl}/icon-gmail.svg`),
        },
        {
          img: (`${this.$assetsUrl}/icon-netflix.svg`),
        },
        {
          img: (`${this.$assetsUrl}/icon-telegram.svg`),
        },
        {
          img: (`${this.$assetsUrl}/icon-youtube.svg`),
        },
        {
          img: (`${this.$assetsUrl}/icon-facebook.svg`),
        },
        {
          img: (`${this.$assetsUrl}/icon-ins.svg`),
        },
        {
          img: (`${this.$assetsUrl}/icon-line.svg`),
        },
        {
          img: (`${this.$assetsUrl}/icon-twitter.svg`),
        },
      ],
      hideConnectBG: false,
      connectLoading: true,
      clickTime: 0.13,
      animation1: [],
      animation2: [],
      test: false,
      isPc: this.$isPc(),
      isShowAnimation: true,
      showPrompt: false,
    };
  },
  // props: {
  //   isShowAnimation: true
  // },
  watch: {
    isShowAnimation: {
      immediate: true,    // 这句重要
      handler(val) {
        if (!this.animation1 || !this.animation2 || !this.test) {
          return;
        }

        if (val) {
          Array.prototype.forEach.call(this.animation1, function (el) {
            el.resume();
          });
          Array.prototype.forEach.call(this.animation2, function (el) {
            el.resume();
          });
        } else {
          Array.prototype.forEach.call(this.animation1, function (el) {
            el.pause();
          });
          Array.prototype.forEach.call(this.animation2, function (el) {
            el.pause();
          });
        }
      }
    },
  },
  mounted() {
    this.goConnect()
  },
  methods: {
    gotoHomePage() {
      if (this.isWechat()) {
        this.showPrompt = true;
      } else {
        if (window.location.search) {
          window.open(`https://jetstream.tel/zh-CN/account/signup${window.location.search}`);
        } else {
          window.open('https://jetstream.tel/zh-CN/account/signup?affiliateCode=pahjgeewa');
        }
      }
    },
    hidePrompt() {
      this.showPrompt = false;
    },
    isWechat() {
      const ua = window.navigator.userAgent.toLowerCase();
      return ua.indexOf('micromessenger') > -1;
    },
    goConnect() {
      const that = this;
      TweenMax.fromTo(
          document.getElementById('connect-stand-by'),
          that.clickTime,
          { transform: 'scale(1)' },
          {
            transform: 'scale(0.9)',
            delay: 2,
            onComplete: function () {
              TweenMax.fromTo(
                  document.getElementById('connect-stand-by'),
                  that.clickTime,
                  { transform: 'scale(0.9)' },
                  { transform: 'scale(1)' }
              );
            }
          }
      );

      TweenMax.fromTo(
          document.getElementById('connect-stand-by-by'),
          that.clickTime,
          {
            transform: 'scale(1)',
            opacity: 0
          },
          {
            transform: 'scale(0.9)',
            opacity: 0.3,
            delay: 2,
            onComplete: function () {
              TweenMax.fromTo(
                  document.getElementById('connect-stand-by-by'),
                  that.clickTime,
                  {
                    transform: 'scale(0.9)',
                    opacity: 0.3
                  },
                  {
                    transform: 'scale(1)',
                    opacity: 0,
                    onComplete: function () {
                      that.connectLoading = false;
                      TweenMax.fromTo(
                          document.getElementById('connect-loading'),
                          3,
                          {
                            rotationZ: 0,
                            rotationX: 19
                          },
                          {
                            rotationZ: -(360 * 3),
                            rotationX: 19,
                            ease: Power2.easeInOut,
                            onComplete: function () {
                              that.lottieStart();
                            }
                          });
                    }
                  }
              );
            }
          }
      );
    },
    lottieStart() {
      this.connectLoading = true;
      lottie.loadAnimation({
        wrapper: document.getElementById('lottie-centre'),
        animType: 'svg',
        loop: true,
        animationData: connectedJson
      });

      this.hideConnectBG = true;

      lottie.loadAnimation({
        wrapper: document.getElementById('lottie-centre-arrow'),
        animType: 'svg',
        loop: false,
        animationData: connectedArrowJson
      });


      let that = this;
      Array.prototype.forEach.call(document.getElementsByClassName('move-block'), function (el, index) {
        TweenMax.set(el, {opacity: 1})
        TweenMax.fromTo(el, 1.5, { rotation: 0 }, {
          css: { rotation: (((index + 1) * 360) / that.iconList.length) },
          delay: (index * 0.02),
          ease: Power1.easeOut,
          onComplete: function () {
            that.test = true;
            that.animation1[index] = TweenMax.fromTo(el, 24, { rotation: (((index + 1) * 360) / that.iconList.length) }, {
              css: { rotation: (((index + 1) * 360) / that.iconList.length) + 360 },
              repeat: -1,
              ease: Power0.easeNone
            });
            that.animation2[index] = TweenMax.fromTo(el.getElementsByClassName('move-icon')[0], 24, { rotation: -(((index + 1) * 360) / that.iconList.length) }, {
              css: { rotation: -(((index + 1) * 360) / that.iconList.length) - 360 },
              repeat: -1,
              ease: Power0.easeNone
            });
          },
          onStart: function () {
            TweenMax.fromTo(el.getElementsByClassName('move-icon')[0], 1.5, { rotation: 0 }, {
              css: { rotation: -(((index + 1) * 360) / that.iconList.length) },
              ease: Power1.easeOut
            });
          }
        });
      });


    }
  }
};
</script>

<style lang="scss">
.preview-session-container {
  padding: 0 !important;
  overflow: hidden;
  position: relative;

  .content {
    display: flex;
    padding: 160px 0;
    flex-direction: row;
    .content-left {
      display: flex;
      flex-direction: column;
      width: 400px;
      .title {
        font-size: 39px;
        font-weight: 600;
        line-height: 56px;
        margin-bottom: 0;
        text-align: left;
      }
      .title-end {
        margin-bottom: 40px;
      }
      .description {
        font-size: 21px;
        font-weight: 400;
        line-height: 38px;
        margin-bottom: 60px;
        color: #616577;
        text-align: left;

      }
      .use-btn {
        height: 52px;
        width: 200px;
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 40px;
        background-color: #3963EF;
        border-color: #3963EF;
        box-shadow: 0 3px 6px rgba(86, 109, 186, 0.4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        line-height: 24px;
        &:hover {
          box-shadow: 0 6px 14px rgba(86, 109, 186, 0.4);
        }
        &:active {
          background-color: #3257D2;
          border-color: #3257D2;
          box-shadow: 0 6px 14px rgba(86, 109, 186, 0.4);
        }
      }

      .bottom-description {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .bottom-description-item {
          font-size: 17px;
          line-height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & span {
          margin-left: 8px;
        }
      }
    }
    .ani-container {
      display: flex;
      justify-content: center;
      position: absolute;
      margin-left: 561px;
      .show-time-circle {
        z-index: 0;
        /*position: absolute;*/
        /*margin-left: 561px;*/
        background: no-repeat center;
        background-size: contain;
        width: 560px;
        display: inline-block;
        height: 560px;
        /*animation: testCir 24s linear infinite normal;*/
      }
      .show-time-phone {
        z-index: 1;
        position: absolute;
        /*margin-left: 716px;*/
        margin-top: 148px;
        background: no-repeat center;
        background-size: contain;
        width: 250px;
        display: inline-block;
        height: 506px;
        /*animation: testCir 24s linear infinite normal;*/
      }

      .lottie-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 234px;
        height: 234px;
        position: absolute;
        /*margin-left: 724px;*/
        margin-top: 200px;
        .connect-stand-by {
          position: absolute;
          cursor: pointer;
          z-index: 4;
          width: 120px;
          height: 120px;
          background: no-repeat center;
          background-size: contain;
          display: inline-block;
          /*animation: testCir 24s linear infinite normal;*/
        }
        .connect-stand-by-by {
          position: absolute;
          cursor: pointer;
          z-index: 6;
          width: 108px;
          height: 108px;
          background: #012c76;
          background-size: contain;
          display: inline-block;
          border-radius: 50%;
          opacity: 0;
        }
        .connect-stand-by-hide {
          display: none;
        }
        .lottie-centre {
          position: absolute;
          z-index: 3;
          width: 145px;
          height: 145px;
        }
        .lottie-centre-arrow {
          position: absolute;
          z-index: 3;
          width: 250px;
          height: 250px;
        }
        .connect-loading {
          transform: rotateX(19deg);
          z-index: 3;
          width: 140px;
          height: 140px;
          background: no-repeat center;
          background-size: contain;
          display: inline-block;
        }
        .connect-loading-hide {
          display: none;
        }
      }
      .move-block {
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 558px;
        height: 558px;
        position: absolute;
        /*margin-left: 560px;*/
        /*margin-top: -14px;*/
        /*background-color: aqua;*/
        //z-index: -3;
        /*animation: testCir 24s linear infinite normal;*/
        /*animation-fill-mode: forwards;*/
        .move-icon {
          position: relative;
          margin-bottom: -34px;
          /*animation: testCir2 24s linear infinite normal;*/
        }
      }
    }
  }
}

.preview-session-container-mb {
  padding: 0.8rem 0.533rem 0 0.533rem;
  overflow: hidden !important;
  position: relative;
  .content {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    .content-left {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.333rem;
      .title {
        font-size: 0.747rem;
        font-weight: 600;
        line-height: 1.013rem;
        margin-bottom: 0;
        text-align: left;
      }
      .title-end {
        margin-bottom: 0.8rem;
      }
      .description {
        font-size: 0.48rem;
        font-weight: 400;
        line-height: 0.827rem;
        margin-bottom: 1.067rem;
        color: #616577;
        text-align: left;
      }
      .use-btn {
        height: 1.173rem;
        width: 3.307rem;
        font-size: 0.427rem;
        line-height: 0.573rem;
        font-weight: 400;
        margin-bottom: 0.533rem;
        background-color: #3963EF;
        border-color: #3963EF;
        box-shadow: 0 0.04rem 0.08rem rgba(86, 109, 186, 0.4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
x        &:hover {
          box-shadow: 0 0.08rem 0.187rem rgba(86, 109, 186, 0.4);
        }
        &:active {
          background-color: #3257D2;
          border-color: #3257D2;
          box-shadow: 0 0.08rem 0.187rem rgba(86, 109, 186, 0.4);
        }
      }

      .bottom-description {
        margin-top: .4rem;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;

        .bottom-description-item {
          font-size: 17px;
          line-height: 24px;
          width: 50%;
          display: flex;
          align-items: center;
          padding-top: .2rem;
          padding-bottom: .2rem;
        }

        & span {
          margin-left: 0.2rem;
        }
      }
    }
    .ani-container {
      display: flex;
      justify-content: center;
      .show-time-circle {
        z-index: 0;
        background: no-repeat center;
        background-size: contain;
        width: 8.107rem;
        display: inline-block;
        height: 8.107rem;
      }
      .show-time-phone {
        z-index: 2;
        position: absolute;
        /*margin-left: 716px;*/
        margin-top: 2.16rem;
        background: no-repeat center;
        background-size: contain;
        width: 3.613rem;
        display: inline-block;
        height: 7.32rem;
        /*animation: testCir 24s linear infinite normal;*/
      }

      .lottie-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.4rem;
        height: 3.4rem;
        position: absolute;
        /*margin-left: 724px;*/
        margin-top: 2.973rem;
        .connect-stand-by {
          position: absolute;
          cursor: pointer;
          z-index: 4;
          width: 1.553rem;
          height: 1.553rem;
          background: no-repeat center;
          background-size: contain;
          display: inline-block;
          /*animation: testCir 24s linear infinite normal;*/
        }
        .connect-stand-by-by {
          position: absolute;
          cursor: pointer;
          z-index: 6;
          width: 1.451rem;
          height: 1.451rem;
          background: #012c76;
          background-size: contain;
          display: inline-block;
          border-radius: 50%;
          opacity: 0;
        }
        .connect-stand-by-hide {
          display: none;
        }
        .lottie-centre {
          position: absolute;
          z-index: 3;
          width: 1.953rem;
          height: 1.953rem;
        }
        .lottie-centre-arrow {
          position: absolute;
          z-index: 3;
          width: 3.153rem;
          height: 3.153rem;
        }
        .connect-loading {
          transform: rotateX(19deg);
          z-index: 3;
          width: 1.853rem;
          height: 1.853rem;
          background: no-repeat center;
          background-size: contain;
          display: inline-block;
        }
        .connect-loading-hide {
          display: none;
        }
      }
      .move-block {
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 8.107rem;
        height: 8.107rem;
        position: absolute;
        /*margin-left: 560px;*/
        /*margin-top: -14px;*/
        /*background-color: aqua;*/
        //z-index: -3;
        /*animation: testCir 24s linear infinite normal;*/
        /*animation-fill-mode: forwards;*/
        .move-icon {
          position: relative;
          margin-bottom: -0.493rem;
          width: 0.987rem;
          height: 0.987rem;
          /*animation: testCir2 24s linear infinite normal;*/
        }
      }
    }
  }
}

.dialog-bg {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(31, 34, 46, 0.7);
}

@keyframes testCir {
  0% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes testCir2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
</style>
