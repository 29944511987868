<template>
  <Header/>
  <div class="content-container">
    <div class="content">
      <HomePreviewSession></HomePreviewSession>
    </div>

  </div>
  <div class="content-container advantage">
    <div class="content">
      <Advantage></Advantage>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header";
import HomePreviewSession from "./components/HomePreviewSession";
import Advantage from "./components/Advantage";

export default {
  name: 'App',
  components: {
    Advantage,
    HomePreviewSession,
    Header,
  },
}
</script>

<style lang="css">
body {
  margin: 0;
}

#app{
  font-family: Open Sans, sans-serif;
  height: 100%;
  background: #fff;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;
  color: #242C45;
  text-align: center;
}

.content {
  max-width: 1170px;
  width: 100%;
  min-width: fit-content;
}

.advantage {
  background: #F9FBFC;
}

.advantage-mb {
  height: 7.76rem;
}

.home-container {

}
.home-container-pc {
  width: 100vw;
  min-height: 100%;
  height: fit-content;
  min-width: fit-content;
  align-items: center;
  overflow: auto;
}
</style>
